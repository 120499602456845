import React from 'react'
import { BsFillBuildingFill } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { PiStudentBold } from "react-icons/pi";
import { LuFileSpreadsheet } from "react-icons/lu";
import { FaRegCalendarDays } from "react-icons/fa6";
import { FiShoppingCart } from 'react-icons/fi';
import { BiArrowToTop } from "react-icons/bi";
import { CiInboxOut } from "react-icons/ci";
import NavComp from '../NavComp';

const CaissierRole = () => {
    const activeLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg text-white bg-blue-600 text-md m-2';
    const normalLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg  text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
    const { setActiveMenu, screenSize, currentColor } = useStateContext();


    const handleCloseSidebar = () => {
        if (screenSize <= 900) {
            setActiveMenu(false);

        }
    }
    return (
        <div>
            <NavComp link={`/classes`} onClick={handleCloseSidebar} label="Classes" labelAr="الأقسام" icon={<BsFillBuildingFill />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor} />
            <NavComp link={`/inscriptions`} onClick={handleCloseSidebar} label="Inscriptions" labelAr="التسجيلات" icon={<PiStudentBold />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor} />
            <NavComp link={`/factures`} onClick={handleCloseSidebar} label="Factures" labelAr="الفواتــيــر" icon={<LuFileSpreadsheet />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor} />
            <NavComp link={`/depenses`} onClick={handleCloseSidebar} label="Dépenses" labelAr="المصاريف" icon={<FiShoppingCart />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor} />
            <NavComp link={`/alimentations`} onClick={handleCloseSidebar} label="Alimentations" labelAr="المداخيل الأخرى" icon={<BiArrowToTop />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor} />
            <NavComp link={`/Sorties`} onClick={handleCloseSidebar} label="Alimentations" labelAr="المخاريج" icon={<CiInboxOut />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor} />
            <NavComp link={`/situations`} onClick={handleCloseSidebar} label="Situations" labelAr="وضعية الصندوق" icon={<FaRegCalendarDays />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor} />
        </div>
    )
}

export default CaissierRole